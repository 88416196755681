.privacy-hero-container {
  height: 28.125rem;
  display: flex;
  justify-content: left;
  align-items: center;
  color: white;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-left: 6.125rem;
  padding-right: 6.125rem;
  z-index: 10;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba(67, 52, 182, 0.6);
}
.privacy-hero-container .waves-bg {
  position: absolute;
  top: 0;
  left: -12px;
  width: 100%;
  height: 120%;

  background-size: cover;
  overflow-x: visible;
  animation: wave 7s infinite;
  z-index: 5;
}

.privacy-hero-container .bottom-waves-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

@keyframes wave {
  0% {
    transform: translateY(0) translateX(0); /* Start with no movement */
  }
  50% {
    transform: translateY(-10px) translateX(10px); /* Adjust amplitude and horizontal movement */
  }
  100% {
    transform: translateY(0) translateX(0); /* Return to initial position */
  }
}

@media only screen and (max-width: 768px) {
  .privacy-hero-container {
    padding-left: 2.93rem;
    padding-right: 2.93rem;
    margin-top: 4rem;
    padding-top: calc((25.56rem - 11.61rem) / 2);
    height: 25.56rem;
    align-items: center;
  }
  .privacy-hero-container .waves-bg {
  
    background-size: cover;
    height:100%;
    background-repeat: no-repeat;
  
  }
  
 
}

@media only screen and (max-width: 450px){
  .privacy-hero-container {
   height: 30rem;
  }

  
  
}