.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  object-fit: cover;
}

.swiper {
  width: 100%;
  height: 80px;
  margin: 20px auto;
  padding: 0 50px;
}

.swiper-button-prev::after {
  border: 2px solid;
  min-width: 2.75rem;
  width: 2.75rem;
  height: 2.75rem;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 0.25rem;
  border-radius: 100%;
  /* border-image: linear-gradient(45deg, #7500e9, #495cec) 1; */

}

.swiper-button-next::after {
  border: 2px solid;
  min-width: 2.75rem;
  width: 2.75rem;
  height: 2.75rem;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0.25rem;
  /* border-image: linear-gradient(45deg, #7500e9, #495cec) 1; */
  border-radius: 100%;
}