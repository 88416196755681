.pricing-card-container {
  display: flex;
  justify-content: center;
}

.linear-gradient-bg {
  background: linear-gradient(
      180deg,
      rgba(67, 52, 182, 0.37) 0%,
      rgba(159, 147, 250, 0.08) 100%
    ),
    linear-gradient(0deg, #ffffff, #ffffff);
}
.bg-transparent {
  background-color: transparent !important;
}

.pricing-card-container .pricing-plan-card {
  width: 30.5rem;
  height: 50.18rem;
  background-color: white;
  border: 1px solid #acadad;
  border-radius: 26px;
  padding: 2.5rem;
  position: relative;
  z-index: 98;
  overflow: hidden !important;
  margin: 1rem;
}
.pricing-card-container .pricing-plan-card .pricing-card-image {
  position: absolute;
}
.postion-top-right {
  right: 0;
  top: 10%;
}
.postion-right {
  right: 0;
}
.position-top {
  top: 0;
  left: 0;
}
.position-left {
  top: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
}

.pricing-card-container .pricing-plan-card .plan-card-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  height: 100%;
}

.pricing-card-container
  .pricing-plan-card
  .plan-card-content
  .plan-card-text
  .plan-card-list
  .plan-card-list-item {
  display: flex;
  padding: 0.5rem 0;
}
.pricing-card-container
  .pricing-plan-card
  .plan-card-content
  .plan-card-btn-container {
  width: 100%;
  z-index: 100;
}
.pricing-card-container
  .pricing-plan-card
  .plan-card-content
  .plan-card-btn-container
  .plan-card-btn {
  background-color: #4334b6;
  color: white;
  padding: 1.375rem 2.75rem;
  border-radius: 12px;
  width: 100%;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.5rem;
}
@media only screen and (max-width: 768px) {
  .bg-transparent {
    background: linear-gradient(
      180deg,
      rgba(67, 52, 182, 0.37) 0%,
      rgba(156, 143, 255, 0) 100%
    );
  }
}

@media only screen and (max-width: 459px) {
  .pricing-card-container
    .pricing-plan-card
    .plan-card-content
    .plan-card-btn-container
    .plan-card-btn {
    padding: 0.5rem 2rem;
    font-size: 1.1rem;
  }
  .pricing-card-container
  .pricing-plan-card
  .plan-card-content
  .plan-card-text
  .plan-card-list
  .plan-card-list-item {
    font-size: 1.1rem;
}
.pricing-card-container .pricing-plan-card {

  height: fit-content;
 
}
}
