.pricing-plans-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pricing-plans-container .pricing-plans-text {
  width: 65%;
}
.pricing-plans-container .pricing-plan1,
.pricing-plans-container .pricing-plan2 {
  width: 100%;
  position: relative;
}
.pricing-plans-container .pricing-plan1 .ellipses-bg1 {
  position: absolute;
  right: 0;
  top: 5%;
  z-index: -1;
}
.pricing-plans-container .pricing-plan1 .pricing-plan-bg2 {
  position: absolute;
  left: 0;
  top: -15%;
  z-index: -1;
}
.pricing-plans-container .pricing-plan2 .pricing-plan-bg2 {
  position: absolute;
  right: 0;
  top: 25%;
}

.pricing-plans-container .pricing-plan1 .pricing-plan-features {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1rem 2rem 4rem 2rem;
}
.pricing-plans-container .pricing-plan2 .pricing-plan-features {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1rem 9rem 4rem 9rem;
}
@media only screen and (max-width: 768px) {
  .pricing-plans-container .pricing-plans-text {
    width: 100%;
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .pricing-plans-container .pricing-plan2 .pricing-plan-features {
    margin: 1rem 2rem 4rem 2rem;
  }
  .pricing-plans-container .pricing-plan1 .pricing-plan-features,
  .pricing-plans-container .pricing-plan2 .pricing-plan-features {
    flex-direction: column;
    align-items: center;
  }
}
