.section-title{
    display: flex;
    border-radius: 8px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
    background: rgba(237, 235, 250, 1);
    width: 11.29rem;
    cursor: pointer; 
    transition: background-color 0.3s ease; 
  }
  
  .section-title.clicked {
    background: rgba(67, 52, 182, 1);
    color: white;
  }
.section-title .section-title-text{
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 15px;
}

