.privacy-info-title{
    display: flex;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
    background: rgba(237, 235, 250, 1);
    width: 11.29rem;
    cursor: pointer; 
    transition: background-color 0.3s ease; 
  }
  
  .privacy-info-title.clicked {
    background: rgba(67, 52, 182, 1);
    color: white;
  }
.privacy-info-title .privacy-info-text{
    display: flex;
    text-align: left;
    font-weight: 400;
    font-size: 15px;
}

.privacy-info-svg {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem;
}