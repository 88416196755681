.pricing-plan-feature {
  display: flex;
  flex-direction: column;
  width: 16.87rem;
}
.pricing-plan-feature .pricing-plan-feature-title {
  background: linear-gradient(124.85deg, #4334B6 4.69%, #F6393D 253.57%);
/*
  background-image: linear-gradient(
    to top left,
    rgba(246, 57, 61, 1) -57%,
    rgba(67, 52, 182, 1) 100%
  );*/
  color: white;
  width: fit-content;
  font-weight: 400;
  line-height: 30px;
  font-size: 1.25rem;
  padding: 5px 1rem;

  text-align: center;
}
.pricing-plan-feature .pricing-plan-feature-content {
  background-color: rgba(47, 38, 114, 1);
  padding: 1.25rem;
  padding-left: 1rem;
  font-size: 1.5rem;
  line-height: 28.5px;
  color: white;
  text-align: left;
}

.left-right-spacing{
  margin: 0.5rem 1rem 1rem;
}
