.faq-questions-container {
  display: flex;
  padding: 6rem 4rem;
  position: relative;
}
.faq-questions-container .left-bg {
  position: absolute;
  left: 0;
  top: -35%;
  z-index: -1;
}
.faq-questions-container .questions-titles {
  width: 30%;
  display: flex;
  flex-direction: column;
}

.faq-questions-container .question-answer {
  width: 70%;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 768px) {
  .faq-questions-container {
    flex-direction: column;
    padding: 6rem 1rem;
  }
  .faq-questions-container .questions-titles {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .faq-questions-container .questions-titles .questions-titles-items {
    display: flex;
    justify-content: space-between;
    overflow-x: auto;
    white-space: nowrap;
    margin-bottom: 6rem;
  }
  .questions-titles-items::-webkit-scrollbar {
    display: none; 
  }

  .faq-questions-container .question-answer {
    width: 100%;
  }
}
