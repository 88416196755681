.bg-custom-background {
  z-index: 999;
  background-size: 70px 70px;
  background-image:
    linear-gradient(to right, #614f4f 1px, transparent 1px),
    linear-gradient(to bottom, #614f4f 1px, transparent 1px);
}

.slide-in-left {
  animation: slideInLeft 0.5s ease forwards;
  opacity: 0;
  transform: translateX(-50%);
}

@keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(-50%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.slide-in-right {
  animation: slideInRight 0.5s ease forwards;
  opacity: 0;
  transform: translateX(50%);
}

@keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(50%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.outer-container {
  position: relative;
  left: -25rem;
  top: 0;
  width: 50rem;
  height: 50rem;
  margin-top: -3rem;
  border-radius: 50rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(172, 171, 171);
  z-index: 0;
}

.inner-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}

.red-circle {
  width: 18rem;
  height: 20rem;
  border-radius: 50rem;
}

.buttons-container {
  position: absolute;
  right: 0;
  z-index: 2;
}

.rotating-circle {
  position: absolute;
  right: 0;
  width: 50rem;
  height: 50rem;
  border-radius: 30rem;
  border: solid 5px;
  border-color: transparent rgb(255, 255, 255) transparent transparent;
  transition: transform 0.5s ease-in-out;
  z-index: 0;
}

.underline {
  text-decoration-color: #EA98F7;
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.carousel-item {
  width: 100%;
  padding: 20px;
  animation: slideUp 0.5s ease forwards;
}

.carousel-item:nth-child(1) {
  animation-delay: 0.2s;
}

.carousel-item:nth-child(2) {
  animation-delay: 0.4s;
}

.carousel-item:nth-child(3) {
  animation-delay: 0.6s;
}

.carousel-track {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1.5s ease, transform 1.5s ease;
}

.carousel-track.slide-up {
  opacity: 1;
  transform: translateY(0);
}