.about-team-member{
    display: flex;
    justify-content: space-evenly;
    margin:2rem 5rem;
}

.about-team-member-reverse{
  
    flex-direction: row-reverse;
    
    
}

@media only screen and (max-width: 700px) {
    .about-team-member{
        flex-direction: column;
    }
    
    .about-team-member-reverse{
      
        flex-direction: column-reverse;
        
        
    }
}